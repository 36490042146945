@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
/*--------------------------------------------EVERYWHERE--------------------------------------------*/
/*Navbar font type*/

* {
  font-family: "Noto Sans", sans-serif;
  /* font-family: "Noto Serif", serif; */
  font-size: 100%;
}

h2 {
  font-family: "Noto Serif", sans-serif;
  text-align: center;
  margin: 0 0 1rem 0;
  font-size: 2.5rem !important;
  color: #941e40;
}

.default_btn {
  font-family: "Noto Serif", sans-serif;
  z-index: 3;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  display: inline-block;
  padding: 0.6rem 1.3rem;
  border-radius: 5rem;
  transition: 0.5s;
  color: #941e40;
  border: 0.2rem solid #941e40;
  text-decoration: none;
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: #fff5d7;
  text-align: center;
}

.default_btn:hover {
  background: #2f1728;
  color: #941e40;
}

/*--------------------------------------------NAVBAR--------------------------------------------*/
#navbar ul {
  text-align: center;
}

/* Colour of Navbar background*/
.navbar {
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  width: 100%;
}

/* Colour of Navbar brand*/
.navbar-brand {
  font-size: 2rem !important;
  color: #941e40 !important;
  position: absolute;
  left: 0;
  padding-left: 5rem;
  padding-right: 5rem;
}

.navbar a {
  font-family: "Noto Serif", sans-serif;
  font-size: 1.3rem;
  text-transform: capitalize;
  color: #2f1728 !important;
}

.navbar a:hover {
  color: #941e40 !important;
}

.nav-item a:focus {
  font-weight: bold;
  border-bottom: 0.1rem solid #941e40;
}

.Resume_btn {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  display: inline-block;
  padding: 0.2rem 1.5rem;
  border-radius: 5rem;
  transition: 0.5s;
  color: #941e40;
  border: 0.2rem solid #941e40;
  text-decoration: none;
  background-color: #fff5d7;
}

.Resume_btn:hover {
  background: #2f1728;
  color: #941e40;
}

/*-------------------------------------------HOME---------------------------------------------*/
#home {
  position: relative;
  width: 100vw;
  height: 95vh;
  overflow-y: hidden;
  background-color: #feebca;
}

#home h1 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  color: #941e40;
}

.home {
  z-index: 2;
  padding-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.home-brand {
  font-family: "Noto Serif", sans-serif;
  font-size: 3.7rem !important;
  color: #2f1728 !important;
}

/*                                  ---------HOME_ANIMATION-------                                --*/
/*home animation code begins here*/
.sky {
  height: 80vh;
  width: 100%;
  background-image: url(/static/media/sky3.5fc17d36.png);
  background-size: cover;
  background-position: right;
  overflow-x: hidden;
  -webkit-animation: sky 400s linear infinite;
          animation: sky 400s linear infinite;
  background-color: #fff5d7;
}

@-webkit-keyframes sky {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right */
  to {
    background-position: -1000rem 0;
  }
}

@keyframes sky {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right */
  to {
    background-position: -1000rem 0;
  }
}

.city {
  height: 14rem;
  width: 100%;
  background-image: url(/static/media/withoutbackground.ccdcf96e.png);
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  display: block;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  background-repeat: repeat-x;
  -webkit-animation: city 60s linear infinite;
          animation: city 60s linear infinite;
}

@-webkit-keyframes city {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: -1000rem 0;
  }
}

@keyframes city {
  from {
    background-position: 0 0;
  }
  /*use negative width if you want it to flow right to left else and positive for left to right*/
  to {
    background-position: -1000rem 0;
  }
}

/* car body */
.car {
  width: 12rem;
  left: 0%;
  bottom: 1rem;
  transform: translateX(-50%);
  position: absolute;
  z-index: 2;
}

.car img {
  width: 100%;
  -webkit-animation-name: car;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: car;
  -moz-animation-duration: 10s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: car;
  -ms-animation-duration: 10s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: car;
  -o-animation-duration: 10s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;

  animation-name: car;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* -----------------------------------------------------------Car Animation X Cross Browser------------------------------------------ */
@-webkit-keyframes car {
  100% {
    -webkit-transform: translateY(-0.1rem);
  }
  50% {
    -webkit-transform: translateY(0.1rem);
  }
  0% {
    -webkit-transform: translateY(-0.1rem);
  }
}

@keyframes car {
  100% {
    transform: translateY(-0.1rem);
  }
  50% {
    transform: translateY(0.1rem);
  }
  0% {
    transform: translateY(-0.1rem);
  }
}

/* -----------------------------------------------------------Wheel Animation X Cross Browser------------------------------------------ */
.wheel {
  left: 0%;
  bottom: 2.5rem;
  position: absolute;
  z-index: 2;
}

.wheel img {
  width: 2rem;
  height: 2rem;

  -webkit-animation-name: wheel;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: wheel;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: wheel;
  -ms-animation-duration: 1s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: wheel;
  -o-animation-duration: 1s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;

  animation-name: wheel;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.back-wheel {
  left: -3.9rem;
  position: absolute;
}

.front-wheel {
  left: 2.2rem;
  position: absolute;
}

@-webkit-keyframes wheel {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes wheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* -----------------------------------------------------------Vehicle Animation X Cross Browser------------------------------------------ */
.vehicle {
  z-index: 1;
  position: absolute;
  display: block;
  bottom: 0;

  -webkit-animation-name: vehicle;
  -webkit-animation-duration: 11s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: vehicle;
  -moz-animation-duration: 11s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: vehicle;
  -ms-animation-duration: 11s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: vehicle;
  -o-animation-duration: 11s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;

  animation-name: vehicle;
  animation-duration: 11s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes vehicle {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(90vw);
  }
}

@keyframes vehicle {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(90vw);
  }
}
/*-----------------------------------------------ABOUT---------------------------------------------------*/

#about {
  background-color: #fff5d7;
  width: 100%;
}

.about-box {
  padding: 1.5rem;
  background-color: #fff5d7;
}

.about-text {
  justify-content: center !important;
  padding: 1.5rem;
}
.about-text p {
  text-align: center;
  font-size: 1rem;
}

h4 {
  font-family: "Noto Serif", sans-serif;
  text-align: center;
  margin: 0 0 1rem 0;
  color: #941e40;
}
/* .about-img img {
  /* width: 100%; *
  height: auto;
} */

/*                                  ---------ABOUT_ANIMATION----------                                --*/
#about .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(-1rem);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(-1rem);
  }
}

/*-----------------------------------------------PROJECTS + CARD---------------------------------------------------*/
#projects {
  background-color: #fff5d7;
  width: 100%;
}
#projects .box {
  position: relative;
  background-color: #2f1728;
  left: 0rem;
  width: 21rem;
  height: auto;
  border-radius: 1.5rem;
  transition: transform 500ms ease-in-out;
  box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem #a19067;
  margin: 1rem;
}

.box:hover {
  box-shadow: 0.2rem 0.2rem 0.2rem 0.2rem #a19067;
  transform: translateY(-1rem);
}

.box-text {
  height: 15rem;
}

#card h3 {
  font-family: "Noto Serif", sans-serif;
  color: #941e40;
  text-align: center;
  font-weight: bold;
  padding: 1.3rem;
}

#card p {
  font-family: "Noto Sans", sans-serif;
  color: #fff5d7;
  font-size: 1.1rem;
  text-align: center;
  display: block;
}
.box-button {
  font-family: "Noto Serif", sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  display: block;
  padding: 0.5rem 2rem;
  border-radius: 5rem;
  transition: 0.5s;
  color: #2f1728;
  border: 0.3rem solid #941e40;
  text-decoration: none;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  background-color: #fff5d7;
}

.box-button:hover {
  background: #941e40;
  color: #2f1728;
}

/*---------------------------------------------Contact--------------------------------------------------------- */

.Contact {
  widows: 100%;
  height: auto;
  padding-top: 5%;
  padding-bottom: 1%;
  background-color: #fff5d7;
}

.icon-style {
  width: 4rem;
  height: 4rem;
  list-style: none;
  margin: 1rem 2rem;
  display: inline-block;
  font-size: 2rem;
  padding: 1.6rem 1.6rem;
  color: #941e40;
  border: 0.1rem solid #941e40;
  border-radius: 50%;
  transition: 0.5s;
  background-color: #fff5d7;
  text-align: center;
  position: relative;
}

.icon-style:hover {
  list-style: none;
  margin: 1rem 2rem;
  display: inline-block;
  font-size: 2rem;
  padding: 1.6rem 1.6rem;
  background: #2f1728;
  color: #941e40;
}

.icon-style i {
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*---------------------------------------------Footer--------------------------------------------------------- */

.footer {
  widows: 100%;
  width: 100%;
  height: 1%;
}
@media only screen and (max-width: 991px) {
  .navbar-collapse.collapsing .navbar-brand,
  .navbar-collapse.show .navbar-brand {
    opacity: 0;
  }
}

@media (max-width: 881px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  /* .navbar-collapse.collapse {
    display: none !important;
  } */
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-height: 400px) {
  .home {
    padding-top: 1.5rem;
  }
}

